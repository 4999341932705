.card {
    width: 320px;
    height: 340px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.picture {
    height: 50%;
    object-fit: cover;
    object-position: 0 55%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.title{
    padding: 5px 10px;
    font-size: 20px;
    margin: 5px;
}

.details{
    display: flex;
    /* font-family: sans-serif; */
    font-size: 14px;
    justify-content: space-evenly;
}

svg{
    font-size: 32px;
}