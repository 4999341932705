
.banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid rgb(210, 210, 210);
    
}

.logo {
    height: 200%;
    cursor: pointer;
}

.nav {
    display: flex;
    gap: 20px;
    padding: 0 20px;
    cursor: pointer;
}

.nav a{
    text-decoration: none;
    color: black
}


.sidebar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 0;
    max-width: 0;
    height: 100%;
    font-size: 22px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fefefe;
    z-index: 1;
    visibility: hidden;
    transition: width 100ms ease-out;
    border-left: #c2c2c2 1px solid;
    overflow: hidden;
}

.sidebar.sidebarExpanded{
    width: 150px;
    max-width: 150px;
    padding: 20px;
    visibility: visible;
}

.sidebar a,div{
    text-decoration: none;
    color: black;
    max-width: 100%;
}

.closeSidebar{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 100%;
}


.closeSidebar svg {
    max-width: 20px;
    width: 100%;
}